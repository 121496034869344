<template>
    <TopNavigation title="" hideSecondNavbar="true" />

    <div class="CompanyInfo">
        <div class="row">
            <div class="col-12 text-center secTitle">Company Information</div>

            <div class="col-12">
                <div class="planBox border shadow-sm">
                    <div class="row">
                        <div class="col-lg-6">
                            <table class="table table-borderless">
                                <tbody>
                                    <tr class="mb-3">
                                        <td width="35px"><i class="fa-solid fa-building fa-xl"></i></td>
                                        <td>
                                            <div class="fw-bold mb-1">Company</div>
                                            <div>SignOn Sdn Bhd <span class="small">201901038997 (1348327-H)</span></div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><i class="fa-solid fa-location-dot fa-xl"></i></td>
                                        <td>
                                            <div class="fw-bold mb-2">Location</div>
                                            <div>Unit 2-1, The Podium, Tower 3,</div>
                                            <div>UOA Business Park,</div>
                                            <div>No. 1, Jalan Pengaturcara U1/51A</div>
                                            <div>Section U1,</div>
                                            <div>40150 Shah Alam</div>
                                            <div>Selangor, Malaysia.</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><i class="fa-solid fa-envelope fa-xl"></i></td>
                                        <td>
                                            <div class="fw-bold mb-2">Email</div>
                                            <div><a href="mailto: wecare@signon.my">wecare@signon.my</a></div>
                                        </td>
                                    </tr>

                                    
                                </tbody>
                            </table>
                        </div>
                        <div class="col-lg-6">
                            <table class="table table-borderless">
                                <tbody>
                                    <tr>
                                        <td width="35px"><i class="fa-solid fa-money-check-dollar fa-xl"></i></td>
                                        <td>
                                            <div class="fw-bold mb-2">Bank Account / Offline Pyament</div>
                                            <div>
                                                <div>Public Bank Berhad</div>
                                                <div>3228838207</div>
                                                <div>SWIFT code: PBBEMYKL</div>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 footer">
            <Footer />
        </div>
    </div>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'CompanyInfo',
    components: { TopNavigation, Footer },
    setup () {
        const navigateToHome = () => {
            window.location.href = window.location.origin + '/#home'
        }

        return { navigateToHome }
    }
}
</script>

<style>
.CompanyInfo {
    max-width: 1000px;
    margin: 60px auto 20px auto;
    border-radius: 10px;
} 

.lblTitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.43;
    color: var(--section-title);
    font-family: "Inter-Medium", sans-serif;
}

.planBox {
    border-radius: 10px;
    /* padding: 0px 22px 25px 22px; */
    padding: 10px;
    margin-bottom: 25px;
    margin-left: 2px;
    margin-right: 2px;
    color: #6E777E;
}

.secTitle {
    font-size: 64px;
    font-weight: bold;
    color: #6E777E;
    font-family: "TT-Firs-Neue-Demi-Bold", sans-serif;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -0.02em;
    padding-bottom: 38px;
}
</style>